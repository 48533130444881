import * as React from "react";
import Parse from "parse";

import {
  Typography,
  Button,
  List,
  Switch,
  message,
  Modal,
  Dropdown,
  Menu,
  Icon,
} from "antd";
import { useTranslation, useUrlParam } from "opendash";
import { useParseQuery } from "parse-hooks";
import { Offer, ListContainer, TitleContainer, FormatWeekdays } from "..";

const containerStyle: React.CSSProperties = {
  // padding: 24,
};

const createButtonStyle: React.CSSProperties = {
  float: "right",
};

export function RideOfferOverview({}) {
  const [t] = useTranslation("ride-sharing");
  const [, setCreateView] = useUrlParam("create", false, "json");

  const query = React.useMemo(
    () =>
      new Parse.Query(Offer)
        .include("route")
        // this is valid syntax for includes
        // which is not supported by typescript
        // @ts-ignore
        .include("route.start")
        // @ts-ignore
        .include("route.end")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  React.useEffect(() => {
    if (!loading && result.length === 0) {
      setCreateView(true);
    }
  }, [result, loading]);

  return (
    <>
      <TitleContainer>
        <Button
          type="primary"
          shape="circle"
          style={createButtonStyle}
          icon="plus"
          title={t("offer.create.action")}
          onClick={() => {
            setCreateView(true);
          }}
        />

        {t("offer.list.title")}
      </TitleContainer>

      <ListContainer>
        <List
          loading={loading}
          dataSource={result}
          renderItem={item => {
            const onSwitch = () => {
              const nextValue = !item.get("active");

              item.set("active", nextValue);
              item.save().then(
                ok => {
                  if (nextValue) {
                    message.success(t("offer.toggle.success_active"));
                  } else {
                    message.success(t("offer.toggle.success_inactive"));
                  }

                  reload();
                },
                error => {
                  message.error(t("offer.toggle.error"));
                }
              );
            };

            const onDelete = () => {
              Modal.confirm({
                title: t("offer.delete.confirm_title"),
                content: t("offer.delete.confirm_content"),
                okText: t("offer.delete.ok"),
                okType: "danger",
                onOk: () => {
                  item.destroy().then(
                    ok => {
                      message.success(t("offer.delete.success"));
                      reload();
                    },
                    error => {
                      console.error(error);
                      message.success(t("offer.delete.error"));
                    }
                  );
                },
              });
            };

            const menu = (
              <Menu>
                <Menu.Item key="toggle" onClick={onSwitch}>
                  {/* <Icon type="check" /> */}
                  <span>{t("offer.toggle.action")}</span>
                  <div style={{ paddingTop: 10 }}>
                    <Switch
                      checked={item.get("active")}
                      style={{ margin: "0 auto", display: "block" }}
                    />
                  </div>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="delete" onClick={onDelete}>
                  <Icon type="delete" />
                  <span>{t("offer.delete.action")}</span>
                </Menu.Item>
                <Menu.Item key="edit" disabled>
                  <Icon type="edit" />
                  <span>{t("offer.edit.action")}</span>
                </Menu.Item>
              </Menu>
            );

            return (
              <List.Item
                actions={[
                  // <Switch
                  //   checked={item.get("active")}
                  //   onChange={onSwitch}
                  //   style={{ margin: "0 auto", display: "block" }}
                  // />,
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    children={
                      <Button type="default" shape="circle" icon="more" />
                    }
                  />,
                ]}
              >
                <List.Item.Meta
                  title={`${item
                    .get("route")
                    .get("start")
                    .get("title")} → ${item
                    .get("route")
                    .get("end")
                    .get("title")}`}
                  description={
                    <>
                      <FormatWeekdays days={item.get("weekdays")} />
                      <span> @ {item.get("time")}</span>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </ListContainer>
      <div style={{ textAlign: "center", padding: 24 }}>
        <Button
          type="primary"
          size="large"
          children={t("offer.create.action")}
          onClick={() => {
            setCreateView(true);
          }}
        />
      </div>
    </>
  );
}
