import * as React from "react";
import Parse from "parse";

import { Button, List, Modal, message } from "antd";
import { useTranslation } from "opendash";
import { useParseQuery } from "parse-hooks";
import { UserBlacklist } from "..";

import moment from "moment";
export function UserBlacklistList({}) {
  const [t] = useTranslation("ride-sharing");

  const query = React.useMemo(
    () =>
      new Parse.Query(UserBlacklist)
        .include("target")
        // @ts-ignore // this is valid syntax
        .include("target.rsProfilePrivate")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  return (
    <List
      loading={loading}
      dataSource={result}
      renderItem={item => {
        function onDelete() {
          Modal.confirm({
            title: t("user.blacklist.remove_confirm_title"),
            content: t("user.blacklist.remove_confirm_content"),
            okText: t("user.blacklist.remove_ok"),
            onOk: () => {
              item.destroy().then(
                ok => {
                  message.success(t("user.blacklist.remove_success"));
                  reload();
                },
                error => {
                  console.error(error);
                  message.success(t("user.blacklist.remove_error"));
                }
              );
            },
          });
        }

        return (
          <List.Item
            actions={[
              <Button
                type="default"
                icon="undo"
                children={t("user.blacklist.remove_action")}
                title={t("user.blacklist.remove_action_title")}
                onClick={onDelete}
              />,
            ]}
          >
            <List.Item.Meta
              title={item.get("target").get("name")}
              description={t("user.blacklist.added_at", {
                date: moment(item.updatedAt).format("DD.MM.YYYY"),
              })}
            />
          </List.Item>
        );
      }}
    />
  );
}
