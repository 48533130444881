import Parse, { User, Relation, Pointer } from "parse";
import { StorageOptionsInterface } from "opendash";

export interface AuthorityAttributes {
  name: string;
}

export class Authority extends Parse.Object<AuthorityAttributes> {
  constructor(data: AuthorityAttributes) {
    super("RideSharingAuthority", data);
  }
}

export interface LocationAttributes {
  osm?: number;
  location: Parse.GeoPoint;
  title: string;
  description: string;
}

export class Location extends Parse.Object<LocationAttributes> {
  static StorageOptions: StorageOptionsInterface<Location> = {
    encode: value => {
      return JSON.stringify(value);
    },
    decode: value => {
      return new Location(JSON.parse(value));
    },
  };

  constructor(data: LocationAttributes) {
    super("RideSharingLocation", data);
  }

  static equals(a: Location, b: Location): boolean {
    if (a.get("location").latitude !== b.get("location").latitude) {
      return false;
    }

    if (a.get("location").longitude !== b.get("location").longitude) {
      return false;
    }

    if (a.get("osm") !== b.get("osm")) {
      return false;
    }

    return true;
  }
}

export interface NotificationAttributes {}

export class Notification extends Parse.Object<NotificationAttributes> {
  constructor(data: NotificationAttributes) {
    super("RideSharingNotification", data);
  }
}

export interface OfferAttributes {
  user: Parse.User;
  route: OfferRoute;
  places?: Parse.Relation<Place>;
  time: string;
  weekdays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  active: boolean;
}

export class Offer extends Parse.Object<OfferAttributes> {
  constructor(data: OfferAttributes) {
    super("RideSharingOffer", data);
  }
}

export interface OfferRouteAttributes {
  start: Location;
  end: Location;
  route: Parse.Polygon;
  routeFull: any;
}

export class OfferRoute extends Parse.Object<OfferRouteAttributes> {
  constructor(data: OfferRouteAttributes) {
    super("RideSharingOfferRoute", data);
  }
}

export interface SearchAttributes {
  user?: Parse.User;
  start: Location;
  end: Location;
  time: string;
  weekdays: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  public: boolean;
}

export class Search extends Parse.Object<SearchAttributes> {
  constructor(data: SearchAttributes) {
    super("RideSharingSearch", data);
  }
}

export interface ChatAttributes {
  requestUser?: Parse.User;
  offerUser?: Parse.User;
  requestUserProfile?: ProfilePublic;
  offerUserProfile?: ProfilePublic;
  offer: Offer;
  start: Place;
  end: Place;
  time: string;
  date: number;
  locked: boolean;
  lastMessageAt: Date;
  lastMessageUser: Parse.User;
}

export class Chat extends Parse.Object<ChatAttributes> {
  constructor(data: ChatAttributes) {
    super("RideSharingChat", data);
  }
}

export interface QuickMessageAttributes {
  label: string;
  message: string;
}

export class QuickMessage extends Parse.Object<QuickMessageAttributes> {
  constructor(data: QuickMessageAttributes) {
    super("RideSharingQuickMessage", data);
  }
}

export interface TaxiInfoAttributes {
  name: string;
  phone: string;
  postalCodes: string[];
}

export class TaxiInfo extends Parse.Object<TaxiInfoAttributes> {
  constructor(data: TaxiInfoAttributes) {
    super("RideSharingTaxiInfo", data);
  }
}

export interface MessageAttributes {
  user?: Parse.User;
  chat: Chat;
  message: string;
}

export class Message extends Parse.Object<MessageAttributes> {
  constructor(data: MessageAttributes) {
    super("RideSharingMessage", data);
  }
}

export interface PlaceAttributes {
  name: string;
  type: string;
  location: Parse.GeoPoint;
  expiresAt: Date;
  authority: Authority;
  osm: boolean;
  enabled: boolean;
}

export class Place extends Parse.Object<PlaceAttributes> {
  constructor(data: PlaceAttributes) {
    super("RideSharingPlace", data);
  }
}

export interface ProfilePublicAttributes {}

export class ProfilePublic extends Parse.Object<ProfilePublicAttributes> {
  constructor(data: ProfilePublicAttributes) {
    super("RideSharingProfilePublic", data);
  }
}

export interface ProfilePrivateAttributes {
  blacklist: Relation<Parse.User>;
}

export class ProfilePrivate extends Parse.Object<ProfilePrivateAttributes> {
  constructor(data: ProfilePrivateAttributes) {
    super("RideSharingProfilePrivate", data);
  }
}

export interface UserBlacklistAttributes {
  user: Parse.User;
  target: Parse.User;
}

export class UserBlacklist extends Parse.Object<UserBlacklistAttributes> {
  constructor(data: UserBlacklistAttributes) {
    super("RideSharingUserBlacklist", data);
  }
}

export interface UserReportAttributes {
  user: Parse.User;
  target: Parse.User;
  message: string;
}

export class UserReport extends Parse.Object<UserReportAttributes> {
  constructor(data: UserReportAttributes) {
    super("RideSharingUserReport", data);
  }
}

Parse.Object.registerSubclass("RideSharingAuthority", Authority);
Parse.Object.registerSubclass("RideSharingLocation", Location);
Parse.Object.registerSubclass("RideSharingNotification", Notification);
Parse.Object.registerSubclass("RideSharingOffer", Offer);
Parse.Object.registerSubclass("RideSharingOfferRoute", OfferRoute);
Parse.Object.registerSubclass("RideSharingSearch", Search);
Parse.Object.registerSubclass("RideSharingChat", Chat);
Parse.Object.registerSubclass("RideSharingMessage", Message);
Parse.Object.registerSubclass("RideSharingQuickMessage", QuickMessage);
Parse.Object.registerSubclass("RideSharingPlace", Place);
Parse.Object.registerSubclass("RideSharingProfilePublic", ProfilePublic);
Parse.Object.registerSubclass("RideSharingProfilePrivate", ProfilePrivate);
Parse.Object.registerSubclass("RideSharingTaxiInfo", TaxiInfo);
Parse.Object.registerSubclass("RideSharingUserBlacklist", UserBlacklist);
Parse.Object.registerSubclass("RideSharingUserReport", UserReport);
