import React from "react";
import Parse from "parse";
import { useParams } from "react-router-dom";

import { AdminUsersList } from "..";

const unverifiedQuery = new Parse.Query(Parse.User)
  .equalTo("rsAuthorityVerified", false)
  .notEqualTo("rsBanned", true)
  .notEqualTo("rsAuthorityAdmin", true)
  .include("rsProfilePublic");

const verifiedQuery = new Parse.Query(Parse.User)
  .equalTo("rsAuthorityVerified", true)
  .notEqualTo("rsBanned", true)
  .notEqualTo("rsAuthorityAdmin", true)
  .include("rsProfilePublic");

const bannedQuery = new Parse.Query(Parse.User)
  .equalTo("rsBanned", true)
  .notEqualTo("rsAuthorityAdmin", true)
  .include("rsProfilePublic");

const adminsQuery = new Parse.Query(Parse.User)
  .equalTo("rsAuthorityAdmin", true)
  .include("rsProfilePublic");

export function AdminUsersRoute() {
  let { tab } = useParams();

  tab = tab ?? "unverified";
  
  return (
    <>
      <AdminUsersList query={unverifiedQuery} active={tab === "unverified"} />
      <AdminUsersList query={verifiedQuery} active={tab === "verified"} />
      <AdminUsersList query={bannedQuery} active={tab === "banned"} />
      <AdminUsersList query={adminsQuery} active={tab === "admins"} />
    </>
  );
}
