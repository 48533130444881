import * as React from "react";

import { useTranslation } from "opendash";

import { RideSearchResultsDefault, RideSearchResultsSimple } from "..";

export function RideSearchResults({}) {
  // const [t, i18n] = useTranslation("ride-sharing");

  // if (i18n.language === "de_accessible") {
  //   return <RideSearchResultsSimple />;
  // }

  return <RideSearchResultsDefault />;
}
