import * as React from "react";

import { useUrlParam } from "opendash";
import { LocationSearch, Location } from "..";
import { Button } from "antd";

interface Props {
  location: Location;
  setLocation: (location: Location) => void;
  urlParam: string;
  label: string;
  style: React.CSSProperties;
}

export function LocationInput({
  location,
  setLocation,
  urlParam,
  label,
  style,
}: Props) {
  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  return (
    <>
      <Button
        size="large"
        style={style}
        children={location ? location.get("title") : label}
        title={label}
        onClick={e => setOpen(true)}
      />

      <LocationSearch
        visible={open}
        label={label}
        onChange={location => {
          setLocation(location);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
