import React from "react";
import Parse from "parse";

import { useObjectState } from "./useObjectState";

type Result<T> = {
  result: T | undefined;
  loading: boolean;
  error: Error | undefined;
  reload: () => void;
};

export function useParseGet<T extends Parse.Object>(
  cls: new (...args: any[]) => T,
  id: string,
  options?: { includes?: string[] }
): Result<T> {
  const [state, setState, resetState] = useObjectState<Result<T>>({
    result: undefined,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (cls && id) {
      // @ts-ignore
      const query = new Parse.Query(cls as Parse.Object);

      if (options?.includes) {
        for (const include of options.includes) {
          query.include(include);
        }
      }

      query.get(id).then(
        (response: T) => {
          setState({
            result: response,
            loading: false,
          });
        },
        (error: Error) => {
          setState({ error, loading: false });
        }
      );
    }
  }

  React.useEffect(() => {
    setState({
      result: undefined,
      loading: true,
      error: undefined,
      reload: fetch,
    });

    fetch();
  }, [cls, id]);

  return state;
}
