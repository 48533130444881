import * as React from "react";

import { useTranslation } from "opendash";

import { List, Button, Drawer, Timeline } from "antd";

import { PublicTransportRideInterface } from "..";

export function PublicTransportList({
  data,
}: {
  data: PublicTransportRideInterface[];
}) {
  const [t] = useTranslation("ride-sharing");

  const [details, setDetails] = React.useState<PublicTransportRideInterface>(
    null
  );

  return (
    <>
      <List
        loading={!data}
        dataSource={data}
        renderItem={item => (
          <List.Item
            onClick={() => setDetails(item)}
            actions={[<Button children="Details" />]}
          >
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      />
      <Drawer
        visible={!!details}
        title={details?.title}
        placement="bottom"
        width={400}
        onClose={() => setDetails(null)}
      >
        <Timeline>
          {details &&
            details.stops.map(stop => (
              <Timeline.Item color="gray">
                <span style={{ fontWeight: "bold" }}>{stop.title}</span>
                <br />
                <span>{stop.description}</span>
              </Timeline.Item>
            ))}
        </Timeline>
      </Drawer>
    </>
  );
}
