import Parse from "parse";
import buffer from "@turf/buffer";
import { GraphhopperPath } from "..";

export function createParsePolygonFromRoute(
  route: GraphhopperPath
): Parse.Polygon {
  try {
    if (route) {
      return new Parse.Polygon(
        buffer(route.points, 100, { units: "meters" }).geometry.coordinates[0]
      );
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}
