import React from "react";
import { Icon } from "antd";

export const fields = {
  login: {
    key: "login",
    type: "input",
    settings: {
      placeholder: "Email",
      prefix: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
    },
    rules: [{ required: true, message: "Es wird eine Email benötigt" }]
  },
  passwordIcon: {
    key: "password",
    type: "input.password",
    settings: {
      placeholder: "Passwort",
      prefix: <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />,
      type: "password"
    },
    rules: [
      { required: true, message: "Es wird ein Passwort benötigt" },
      {
        min: 4,
        message: "Dass Passwort muss mindestens 4 Stellen haben"
      }
    ]
  },
  name: {
    key: "name",
    label: "Name",
    type: "input",
    settings: {},
    rules: [{ required: true, message: "Es wird dein Name benötigt" }]
  },
  company: companies => ({
    key: "company",
    label: "Standort",
    type: "select",
    settings: {
      options: companies.map(company => ({
        value: company.id,
        label: company.get("name")
      }))
    }
  }),
  email: {
    key: "email",
    label: "Email",
    type: "input",
    settings: {},
    rules: [{ required: true, message: "Es wird eine Email benötigt" }]
  },
  password: {
    key: "password",
    label: "Passwort",
    type: "input.password",
    settings: {},
    rules: [
      { required: true, message: "Es wird ein Passwort benötigt" },
      {
        min: 4,
        message: "Dass Passwort muss mindestens 4 Stellen haben..."
      }
    ]
  },
  phone: {
    key: "phone",
    label: "Passwort",
    type: "input.password",
    settings: {},
    rules: [
      { required: true, message: "Es wird ein Passwort benötigt" },
      {
        min: 4,
        message: "Dass Passwort muss mindestens 4 Stellen haben..."
      }
    ]
  }
};
