import React from "react";

import { Alert, message, Button, Tabs } from "antd";
import styled from "styled-components";
import { FormGenerator, useTranslation, useUrlParam } from "opendash";
import Parse from "parse";

import { ListContainer, TitleContainer, UserBlacklistList } from "..";

const DEFAULT_FORM = [
  {
    key: "birthYear",
    label: "In welchem Jahr wurdest du geboren?",
    type: "input.number",
  },
  {
    key: "gender",
    label: "Geschlecht",
    type: "select",
    settings: {
      options: [
        {
          value: "nv",
          label: "Keine Angabe",
        },
        {
          value: "female",
          label: "Weiblich",
        },
        {
          value: "male",
          label: "Männlich",
        },
        {
          value: "other",
          label: "Divers",
        },
      ],
    },
  },
  {
    key: "email",
    label: "Kontakt Email",
    type: "input",
  },
  {
    key: "phone",
    label: "Handy- Nummer",
    type: "input",
  },
  // {
  //   key: "whatsapp",
  //   label: "Whatsapp- Nummer",
  //   type: "input",
  // },
  // {
  //   key: "telegram",
  //   label: "Telegram- Nutzer",
  //   type: "input",
  // },
];

const SEARCH_FORM = [
  {
    key: "searchBuffer",
    label: "Wie viele Meter würdest du laufen, um mitgenommen zu werden?",
    type: "input.number",
  },
  {
    key: "searchWomanOnly",
    label: "Ich will nur mit Frauen fahren",
    type: "switch",
  },
  {
    key: "searchAccessibilityWheelchair",
    label: "Ich brauche ein Rollstuhl gerechtes Auto",
    type: "switch",
  },
];

const OFFER_FORM = [
  {
    key: "offerBuffer",
    label: "Wie viele Meter Umweg würdest du fahren, um jemanden mitzunehmen?",
    type: "input.number",
  },
  {
    key: "licenseYear",
    label: "In welchem Jahr hast du deinen Führerschein gemacht?",
    type: "input.number",
  },
  {
    key: "car",
    label: "Was für ein Auto fährst du?",
    type: "input",
  },
  {
    key: "drivingStyle",
    label: "Wie würdest du deinen Fahrstil beschreiben?",
    type: "textarea",
    settings: {
      rows: 2,
    },
  },
  {
    key: "offerWomanOnly",
    label: "Es dürfen nur Frauen mitfahren",
    type: "switch",
  },
  {
    key: "offerAccessibilityWheelchair",
    label: "Mein Auto ist Rollstuhl gerecht",
    type: "switch",
  },
];

function splitProfiles(state) {
  return [
    {
      birthYear: state.birthYear,
      gender: state.gender,
      email: state.email,
      phone: state.phone,
      whatsapp: state.whatsapp,
      telegram: state.telegram,
      licenseYear: state.licenseYear,
      car: state.car,
      drivingStyle: state.drivingStyle,
      offerWomanOnly: state.offerWomanOnly,
      offerAccessibilityWheelchair: state.offerAccessibilityWheelchair,
    },
    {
      offerBuffer: state.offerBuffer,
      searchBuffer: state.searchBuffer,
      searchWomanOnly: state.searchWomanOnly,
      searchAccessibilityWheelchair: state.searchAccessibilityWheelchair,
    },
  ];
}
function copyProfiles(profilePublic, profilePrivate) {
  profilePublic = JSON.stringify(profilePublic);
  profilePublic = JSON.parse(profilePublic);

  delete profilePublic.objectId;
  delete profilePublic.ACL;
  delete profilePublic.createdAt;
  delete profilePublic.updatedAt;

  profilePrivate = JSON.stringify(profilePrivate);
  profilePrivate = JSON.parse(profilePrivate);

  delete profilePrivate.objectId;
  delete profilePrivate.ACL;
  delete profilePrivate.createdAt;
  delete profilePrivate.updatedAt;

  return Object.assign({}, profilePublic, profilePrivate);
}

export function UserProfileRoute() {
  const [t] = useTranslation("ride-sharing");
  const user = Parse.User.current();

  const profilePublic = user.get("rsProfilePublic");
  const profilePrivate = user.get("rsProfilePrivate");

  const [tab, setTab] = useUrlParam("tab", "default");

  const [state, setState] = React.useState(
    copyProfiles(profilePublic, profilePrivate)
  );
  const [isUnsaved, setUnsaved] = React.useState(false);

  const updateState = React.useCallback(
    (key, value) => {
      setState(current => ({
        ...current,
        [key]: value,
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(() => {
    const [statePublic, statePrivate] = splitProfiles(state);

    Promise.all([
      profilePublic.save(statePublic),
      profilePrivate.save(statePrivate),
    ]).then(
      ok => {
        setState(copyProfiles(profilePublic, profilePrivate));
        setUnsaved(false);

        message.success(t("profile.save_success"));
      },
      error => {
        message.error(t("profile.save_error"));
      }
    );
  }, [state, setState, setUnsaved]);

  const submitSettings = React.useMemo(
    () => ({
      children: t("profile.save_label"),
      disabled: !isUnsaved,
    }),
    [t, isUnsaved]
  );

  const onChange = React.useCallback(
    state => {
      if (!isUnsaved) {
        setUnsaved(true);
      }
    },
    [isUnsaved, setUnsaved]
  );

  return (
    <>
      <TitleContainer>
        {isUnsaved && (
          <Button
            type="primary"
            shape="circle"
            style={{ float: "right" }}
            icon="save"
            title={t("profile.save_label")}
            onClick={onSubmit}
          />
        )}

        {t("profile.label")}
      </TitleContainer>
      {isUnsaved && (
        <div style={{ marginBottom: 24, padding: "0 24px" }}>
          <Alert
            type="info"
            message="Du musst speichern, um die Änderungen zu übernehmen."
          />
        </div>
      )}
      <ListContainer>
        <Tabs activeKey={tab} onChange={setTab}>
          <Tabs.TabPane key="default" tab={t("profile.default_tab")}>
            <FormGenerator
              state={state}
              updateState={updateState}
              onChange={onChange}
              elements={DEFAULT_FORM}
              submit={submitSettings}
              onSubmit={onSubmit}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="search" tab={t("profile.search_tab")}>
            <FormGenerator
              state={state}
              updateState={updateState}
              onChange={onChange}
              elements={SEARCH_FORM}
              submit={submitSettings}
              onSubmit={onSubmit}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="offer" tab={t("profile.offer_tab")}>
            <FormGenerator
              state={state}
              updateState={updateState}
              onChange={onChange}
              elements={OFFER_FORM}
              submit={submitSettings}
              onSubmit={onSubmit}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="blacklist" tab={t("user.blacklist.headline")}>
            <UserBlacklistList />
          </Tabs.TabPane>
        </Tabs>
        <br></br>
      </ListContainer>
    </>
  );
}
