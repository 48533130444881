import * as React from "react";

import { useUrlParam, useTranslation } from "opendash";
import { LocationSearch, Place } from "..";
import { Button, Drawer, List, Checkbox } from "antd";

interface Props {
  pool: Place[];
  value: Place[];
  onChange: (places: Place[]) => void;
  label: string;
  description: string;
  urlParam: string;
  style: React.CSSProperties;
}

export function PlacesInput({
  pool,
  value,
  onChange,
  label,
  description,
  urlParam,
  style,
}: Props) {
  const [t] = useTranslation("ride-sharing");

  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  return (
    <>
      <Button
        size="large"
        style={style}
        children={t("ride.places_select")}
        disabled={!pool || pool.length === 0}
        title={label}
        onClick={e => setOpen(true)}
      />

      <Drawer
        visible={open}
        title={label}
        placement="bottom"
        height={400}
        onClose={() => {
          setOpen(false);
        }}
      >
        <p>{description}</p>

        <List
          loading={!pool}
          dataSource={pool}
          renderItem={place => (
            <List.Item
              actions={[
                <Checkbox
                  checked={value.some(p => p.id === place.id)}
                  onChange={e => {
                    const checked = e.target.checked;

                    if (checked) {
                      onChange([...value, place]);
                    } else {
                      onChange(value.filter(p => p.id !== place.id));
                    }
                  }}
                />,
              ]}
            >
              <List.Item.Meta
                title={place.get("type")}
                description={place.get("name")}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
}
