import * as React from "react";

import Parse from "parse";

import { Button, message } from "antd";
import { useTranslation, useUrlParam } from "opendash";
import {
  FormElement,
  LocationInput,
  TimeInput,
  Offer,
  Location,
  WeekdayInput,
  PlacesInput,
  Place,
  GraphhopperPath,
  RoutesInput,
  Search,
  useRideSearchStart,
  useRideSearchEnd,
  useRideSearchTime,
} from "..";
import { usePlacesNearRoute } from "../hooks/usePlacesNearRoute";

const containerStyle: React.CSSProperties = {
  padding: 24,
};

const switchButtonStyle: React.CSSProperties = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
};

const inputWithLabelStyle: React.CSSProperties = {
  width: "100%",
};

const submitButtonStyle: React.CSSProperties = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

export function RideSearchCreate() {
  const [t] = useTranslation("ride-sharing");

  const [view, setView] = useUrlParam<string>("view", null);

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();

  const [weekdays, setWeekdays] = React.useState<(0 | 1 | 2 | 3 | 4 | 5 | 6)[]>(
    [1, 2, 3, 4, 5]
  );

  return (
    <div style={containerStyle}>
      <FormElement label={t("ride.start_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.start_select")}
          urlParam="select_start"
          location={start}
          setLocation={location => setStart(location)}
        />
      </FormElement>

      <Button
        size="large"
        shape="circle"
        type="primary"
        style={switchButtonStyle}
        icon="retweet"
        title={t("search.switch")}
        disabled={!start || !end}
        onClick={e => {
          setStart(end);
          setEnd(start);
        }}
      />

      <FormElement label={t("ride.end_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.end_select")}
          urlParam="select_end"
          location={end}
          setLocation={location => setEnd(location)}
        />
      </FormElement>

      <FormElement label={t("weekdays.label")} useLabel={false}>
        <WeekdayInput value={weekdays} setValue={setWeekdays} />
      </FormElement>

      <FormElement label={t("ride.time_label")}>
        <TimeInput time={time} setTime={setTime} style={inputWithLabelStyle} />
      </FormElement>

      <Button
        size="large"
        type="primary"
        style={submitButtonStyle}
        disabled={!start || !end || !weekdays?.length}
        children={t("search.create.submit")}
        title={
          !start || !end || !weekdays?.length
            ? t("search.create.submit_tooltip_disabled")
            : t("search.create.submit_tooltip")
        }
        onClick={e => {
          const search = new Search({
            start,
            end,
            time,
            weekdays,
            public: false,
          });

          search.save().then(
            () => {
              message.success(t("search.create.success"));
              setView("list");
            },
            error => {
              message.error(t("search.create.error"));
              console.error(error);
            }
          );
        }}
      />
    </div>
  );
}
