import * as React from "react";

import { useTranslation } from "opendash";
import { RideSearchInputDefault, RideSearchInputSimple } from "..";

export function RideSearchInput({}) {
  const [t, i18n] = useTranslation("ride-sharing");

  if (i18n.language === "de_accessible") {
    return <RideSearchInputSimple />;
  }

  return <RideSearchInputDefault />;
}
