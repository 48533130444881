import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "opendash";
import { Icon, Badge } from "antd";
import { useChatsNewCount } from "../hooks/useChatsNewCount";

const Container = styled.div``;

const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  width: 100%;
  height: 60px;

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 99;

  a {
    display: block;
    width: 100%;
    text-align: center;
    color: inherit;
    height: 60px;
    padding: 6px 0;

    &.active {
      border-bottom: 3px solid #1890ff;
    }
  }
`;

const IconHolder = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
`;

const BodyContainer = styled.div``;

export function AppLayout({ children }) {
  const [t, i18n] = useTranslation("ride-sharing");

  const simple = i18n.language === "de_accessible";

  const messages = useChatsNewCount();

  return (
    <Container>
      <NavigationContainer>
        <NavLink to="search">
          <IconHolder>
            <Icon type="search" />
          </IconHolder>
          <Label>{t("navigation.search")}</Label>
        </NavLink>
        {!simple && (
          <NavLink to="offer">
            <IconHolder>
              <Icon type="car" />
            </IconHolder>
            <Label>{t("navigation.offer")}</Label>
          </NavLink>
        )}
        {!simple && (
          <NavLink to="home">
            <IconHolder>
              <Icon type="home" />
            </IconHolder>
            <Label>{t("navigation.home")}</Label>
          </NavLink>
        )}
        <NavLink to="messenger">
          <Badge count={messages}>
            <IconHolder>
              <Icon type="message" />
            </IconHolder>
            <Label>{t("navigation.messenger")}</Label>
          </Badge>
        </NavLink>
        {!simple && (
          <NavLink to="profile">
            <IconHolder>
              <Icon type="user" />
            </IconHolder>
            <Label>{t("navigation.profile")}</Label>
          </NavLink>
        )}
      </NavigationContainer>
      <BodyContainer>{children}</BodyContainer>
    </Container>
  );
}
