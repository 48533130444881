import React from "react";
import Parse from "parse";

import { Location, Place, useParseQuery } from "..";

type ParseListResult<T> = {
  result: Place[];
  loading: boolean;
  error: Error;
  reload: () => void;
};

export function usePlacesNearLocation<T>(
  location: Location,
  baseQuery?: Parse.Query<Place>
): ParseListResult<T> {
  const query = React.useMemo(
    () =>
      location
        ? (baseQuery || new Parse.Query(Place))
            .equalTo("enabled", true)
            .withinKilometers("location", location.get("location"), 1)
            .limit(4)
        : null,
    [location]
  );

  return useParseQuery(query);
}
