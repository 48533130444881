import React from "react";

import { useTranslation } from "opendash";

export const FormatWeekdays = React.memo(({ days }: { days: number[] }) => {
  const [t] = useTranslation("ride-sharing");

  if (days.length === 7) {
    return t("weekdays.all");
  }

  if ([1, 2, 3, 4, 5].every(x => days.includes(x))) {
    return t("weekdays.all_work");
  }

  return days.map(day => t(`weekdays.day_${day}_2`)).join(", ");
});
