import * as React from "react";

import moment from "moment";

import { useTranslation, useUrlParam } from "opendash";
import {
  FormElement,
  LocationInput,
  TimeInput,
  useRideSearchTime,
  useRideSearchDate,
  useRideSearchStart,
  useRideSearchEnd,
} from "..";

import { Button, DatePicker, Divider, List } from "antd";

const listButtonStyle: React.CSSProperties = {
  margin: "0 auto",
  display: "block",
};

const containerStyle: React.CSSProperties = {
  padding: 24,
};

const switchButtonStyle: React.CSSProperties = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
};

const searchButtonStyle: React.CSSProperties = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

const dividerLabelStyle: React.CSSProperties = {
  fontSize: 14,
  fontWeight: 300,
  color: "inherit",
};

const inputWithLabelStyle: React.CSSProperties = {
  width: "100%",
};

export function RideSearchInputDefault({}) {
  const [t] = useTranslation("ride-sharing");

  const [view, setView] = useUrlParam<string>("view", null);

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();
  const [date, setDate] = useRideSearchDate();

  return (
    <div style={containerStyle}>
      <FormElement label={t("ride.start_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.start_select")}
          urlParam="select_start"
          location={start}
          setLocation={location => setStart(location)}
        />
      </FormElement>

      <Button
        size="large"
        shape="circle"
        type="primary"
        style={switchButtonStyle}
        icon="retweet"
        title={t("search.switch")}
        disabled={!start || !end}
        onClick={e => {
          setStart(end);
          setEnd(start);
        }}
      />

      <FormElement label={t("ride.end_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.end_select")}
          urlParam="select_end"
          location={end}
          setLocation={location => setEnd(location)}
        />
      </FormElement>

      <FormElement label={t("ride.date_label")}>
        <DatePicker
          size="large"
          style={inputWithLabelStyle}
          allowClear={false}
          format="dddd, D. M. YYYY"
          disabledDate={current =>
            current.isBefore(moment().subtract(1, "day"))
          }
          defaultValue={
            moment(date).isBefore(moment()) ? moment() : moment(date)
          }
          onChange={date => {
            setDate(date.startOf("day").valueOf());
          }}
        />
      </FormElement>

      <FormElement label={t("ride.time_label")}>
        <TimeInput style={inputWithLabelStyle} time={time} setTime={setTime} />
      </FormElement>

      <Button
        size="large"
        type="primary"
        style={searchButtonStyle}
        disabled={!start || !end}
        children={t("search.action_label")}
        title={
          !start || !end
            ? t("search.action_tooltip_disabled")
            : t("search.action_tooltip")
        }
        onClick={e => {
          setView("results");
        }}
      />

      {/* <Divider style={dividerLabelStyle} children={t("search.highlights")} />

      <p>...</p> */}

      <Divider style={dividerLabelStyle} children={t("search.label")} />

      <Button
        // size="large"
        type="default"
        style={searchButtonStyle}
        children={t("search.list.action")}
        onClick={e => {
          setView("list");
        }}
      />

      <Button
        // size="large"
        type="default"
        style={searchButtonStyle}
        children={t("search.create.action")}
        onClick={e => {
          setView("create");
        }}
      />
    </div>
  );
}
