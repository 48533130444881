import * as React from "react";

import moment from "moment";

import {} from "opendash";
import { timeFormat } from "..";
import { TimePicker } from "antd";

export function TimeInput({ time, setTime, style }) {
  return (
    <TimePicker
      size="large"
      style={style}
      allowClear={false}
      minuteStep={10}
      defaultValue={moment(time || "8:00", timeFormat)}
      format={timeFormat}
      onChange={date => {
        setTime(date.format(timeFormat));
      }}
    />
  );
}
