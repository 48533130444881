import * as React from "react";

import Parse from "parse";
import moment from "moment";

import { useTranslation, useUrlParam } from "opendash";
import { Chat, getProfileImage } from "..";
import {
  List,
  Avatar,
  Button,
  Menu,
  Icon,
  Dropdown,
  Modal,
  message,
} from "antd";
import { ProfilePrivate, UserBlacklist, UserReport } from "../parse";

export function MessengerListItem({
  chat,
  hasLink,
}: {
  chat: Chat;
  hasLink: boolean;
}) {
  const [t] = useTranslation("ride-sharing");
  const [, setChat] = useUrlParam("chat", null, "string");

  const isMyOffer = chat.get("offerUser").id === Parse.User.current().id;

  const user = chat.get(isMyOffer ? "requestUser" : "offerUser");

  const avatar = getProfileImage(user);

  const title = t(
    isMyOffer ? "messenger.list.title_from" : "messenger.list.title_to",
    {
      name: user.get("name"),
    }
  );

  const start = chat.get("start");
  const end = chat.get("end");

  const date = moment(chat.get("date")).format("DD.MM.");
  const time = chat.get("time");
  const s = `${start.get("type")} (${start.get("name")})`;
  const e = `${end.get("type")} (${end.get("name")})`;

  const description = `${date} @ ${time}, ${s} →  ${e}`;

  function blockUser() {
    Modal.confirm({
      title: t("user.blacklist.confirm_title"),
      content: t("user.blacklist.confirm_content"),
      okText: t("user.blacklist.ok"),
      okType: "danger",
      onOk: () => {
        const blacklist = new UserBlacklist({
          user: Parse.User.current(),
          target: user,
        });

        blacklist.save().then(
          ok => {
            setChat(null);
            message.success(t("user.blacklist.success"));
          },
          error => {
            console.error(error);
            message.success(t("user.blacklist.error"));
          }
        );
      },
    });
  }

  function reportUser() {
    Modal.confirm({
      title: t("user.report.confirm_title"),
      content: t("user.report.confirm_content"),
      okText: t("user.report.ok"),
      okType: "danger",
      onOk: () => {
        const report = new UserReport({
          user: Parse.User.current(),
          target: user,
          message: "",
        });

        report.save().then(
          ok => {
            setChat(null);
            message.success(t("user.report.success"));
          },
          error => {
            console.error(error);
            message.success(t("user.report.error"));
          }
        );
      },
    });
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="blacklist"
        onClick={blockUser}
        title={t("user.blacklist.action_title")}
      >
        <Icon type="stop" />
        <span>{t("user.blacklist.action")}</span>
      </Menu.Item>
      <Menu.Item
        key="report"
        onClick={reportUser}
        title={t("user.report.action_title")}
      >
        <Icon type="exclamation-circle" />
        <span>{t("user.report.action")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <List.Item
      onClick={() => {
        if (hasLink) {
          setChat(chat.id);
        }
      }}
      actions={
        hasLink
          ? [
              <Button
                type="link"
                icon={t("messenger.list.open_icon")}
                children={t("messenger.list.open_label")}
              />,
            ]
          : [
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                children={<Button type="default" shape="circle" icon="more" />}
              />,
            ]
      }
    >
      <List.Item.Meta
        title={title}
        description={description}
        avatar={
          <Avatar
            icon={avatar ? undefined : "user"}
            src={avatar ? avatar : undefined}
            size="large"
          />
        }
      />
    </List.Item>
  );
}
