export function isUserProfileComplete(profile) {
  if (!("isDriver" in profile.attributes)) {
    return false;
  }

  if (profile.get("isDriver") && !profile.get("driverPattern")) {
    return false;
  }

  if (!profile.get("address")) {
    return false;
  }

  if (!["email", "phone", "whatsapp", "telegram"].some(k => profile.get(k))) {
    return false;
  }

  return true;
}
