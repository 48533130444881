import * as React from "react";

import { useTranslation, useUrlParam } from "opendash";

import { getProfileImage, Offer, RideOfferRequest, useChats } from "..";

import { List, Avatar, Button } from "antd";
import { useNavigate } from "react-router";

export function RideOfferResultList({ data }: { data: Offer[] }) {
  const [t] = useTranslation("ride-sharing");
  const navigate = useNavigate();
  const chats = useChats();
  const [selectedOffer, setSelectedOffer] = useUrlParam<string>("offer", null);

  return (
    <>
      <RideOfferRequest
        visible={!!selectedOffer}
        offerId={selectedOffer}
        onClose={() => {
          setSelectedOffer(null);
        }}
      />
      <List
        dataSource={data}
        renderItem={offer => {
          const user = offer.get("user");
          const avatar = getProfileImage(user);
          const chat = chats.find(c => c.get("offer")?.id === offer.id);

          const onClick = () => {
            if (chat) {
              navigate("/ride-sharing/messenger?chat=" + chat.id);
            } else {
              setSelectedOffer(offer.id);
            }
          };

          return (
            <List.Item
              onClick={onClick}
              actions={
                chat
                  ? [<Button children={t("search.request_open")} />]
                  : [
                      <Button
                        type="primary"
                        children={t("search.request_create")}
                      />,
                    ]
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    icon={avatar ? undefined : "user"}
                    src={avatar ? avatar : undefined}
                    size="large"
                  />
                }
                title={t("offer.list.title", { name: user.get("name") })}
                description={"..."}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
}
