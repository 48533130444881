import React from "react";
import { Routes, Route } from "react-router-dom";

import { AdminUsersRoute, useParseUser } from "..";
import { AdminLayout } from "opendash";

export function AdminRoute() {
  const user = useParseUser();
  const isAdmin = user?.get("rsAuthorityAdmin");

  if (!user || !isAdmin) {
    return null;
  }

  return (
    <AdminLayout>
      <Routes>
        <Route path="/users" element={<AdminUsersRoute />} />
        <Route path="/users/:tab" element={<AdminUsersRoute />} />
      </Routes>
    </AdminLayout>
  );
}
