import React from "react";
import { Avatar, List, Button, message } from "antd";
import { Link } from "react-router-dom";

import Parse from "parse";

import { useParseQuery, getProfileImage } from "..";
import { useTranslation } from "opendash";

export function AdminUsersList({ query, active }) {
  const [t] = useTranslation(["ride-sharing"]);
  const users = useParseQuery(active && query);

  React.useEffect(() => {
    if (active && !users.loading) {
      users.reload();
    }
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <List
      loading={users.loading}
      dataSource={users.result}
      renderItem={(user: Parse.Object) => {
        const actions = [];

        if (!user.get("rsAuthorityVerified")) {
          actions.push(
            <Button
              key="check"
              icon="check"
              title={t("admin.users.verify_tooltip")}
              type="default"
              size="small"
              onClick={() => {
                user.set("rsAuthorityVerified", true);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.verify_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.verify_error"));
                  }
                );
              }}
            />
          );
        }

        if (user.get("rsAuthorityVerified")) {
          actions.push(
            <Button
              key="close"
              icon="close"
              title={t("admin.users.unverify_tooltip")}
              type="default"
              size="small"
              disabled={Parse.User.current().id === user.id}
              onClick={() => {
                user.set("rsAuthorityVerified", false);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.unverify_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.unverify_error"));
                  }
                );
              }}
            />
          );
        }

        if (!user.get("rsBanned")) {
          actions.push(
            <Button
              key="lock"
              icon="lock"
              title={t("admin.users.ban_tooltip")}
              type="default"
              size="small"
              disabled={Parse.User.current().id === user.id}
              onClick={() => {
                user.set("rsBanned", true);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.ban_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.ban_error"));
                  }
                );
              }}
            />
          );
        }

        if (user.get("rsBanned")) {
          actions.push(
            <Button
              key="unlock"
              icon="unlock"
              title={t("admin.users.unban_tooltip")}
              type="default"
              size="small"
              onClick={() => {
                user.set("rsBanned", false);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.unban_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.unban_error"));
                  }
                );
              }}
            />
          );
        }

        if (!user.get("rsAuthorityAdmin")) {
          actions.push(
            <Button
              key="plus"
              icon="plus"
              title={t("admin.users.admin_tooltip")}
              type="default"
              size="small"
              onClick={() => {
                user.set("rsAuthorityAdmin", true);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.admin_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.admin_error"));
                  }
                );
              }}
            />
          );
        }

        if (user.get("rsAuthorityAdmin")) {
          actions.push(
            <Button
              key="minus"
              icon="minus"
              title={t("admin.users.unadmin_tooltip")}
              type="default"
              size="small"
              disabled={Parse.User.current().id === user.id}
              onClick={() => {
                user.set("rsAuthorityAdmin", false);

                user.save().then(
                  ok => {
                    message.success(t("admin.users.unadmin_success"));
                    users.reload();
                  },
                  error => {
                    message.error(t("admin.users.unadmin_error"));
                  }
                );
              }}
            />
          );
        }

        const avatar = getProfileImage(user);

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              avatar={
                <Avatar
                  icon={avatar ? undefined : "user"}
                  src={avatar ? avatar : undefined}
                  size="large"
                />
              }
              title={
                <Link to={`/users/${user.id}/profile`}>{user.get("name")}</Link>
              }
              description={user.get("username")}
            />
          </List.Item>
        );
      }}
    />
  );
}
