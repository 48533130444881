import * as React from "react";
import * as Parse from "parse";

import { useTranslation } from "opendash";
import { useParseQuery } from "parse-hooks";

import { TaxiInfo } from "..";

import { List, Button } from "antd";

export function TaxiInfoList() {
  const [t] = useTranslation("ride-sharing");
  const query = React.useMemo(() => new Parse.Query(TaxiInfo), []);
  const { result, loading } = useParseQuery(query);

  return (
    <List
      loading={loading}
      dataSource={result}
      renderItem={taxi => (
        <List.Item
          actions={[<Button children={t("search.results.taxi_call_action")} />]}
          onClick={() => {
            window.open("tel:" + taxi.get("phone"));
          }}
        >
          <List.Item.Meta
            title={taxi.get("name")}
            description={taxi.get("phone")}
          />
        </List.Item>
      )}
    />
  );
}
