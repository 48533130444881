import * as React from "react";
import { useUrlParam } from "opendash";
import {
  RideSearchCreate,
  RideSearchList,
  RideSearchInput,
  RideSearchResults,
} from "..";

export function RideSearchRoute({  }) {
  const [view, setView] = useUrlParam<string>("view", null);

  if (view === "list") {
    return <RideSearchList />;
  }

  if (view === "create") {
    return <RideSearchCreate />;
  }

  if (view === "results") {
    return <RideSearchResults />;
  }

  return <RideSearchInput />;
}
