import * as React from "react";

import {
  useUrlParam,
  useTranslation,
  formatDistance,
  formatRelativeDates,
} from "opendash";
import { Location, Place, GraphhopperPath, useGraphhopper } from "..";
import { Button, Drawer, List } from "antd";

interface Props {
  start: Location;
  end: Location;
  value: GraphhopperPath;
  onChange: (places: GraphhopperPath) => void;
  label: string;
  urlParam: string;
  style: React.CSSProperties;
}

export function RoutesInput({
  start,
  end,
  value,
  onChange,
  label,
  urlParam,
  style,
}: Props) {
  const [t] = useTranslation("ride-sharing");

  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  const { result: routes, loading } = useGraphhopper(start, end);

  React.useEffect(() => {
    if (routes.length === 1) {
      onChange(routes[0]);
    } else {
      onChange(null);
    }
  }, [routes]);

  return (
    <>
      <Button
        size="large"
        style={style}
        loading={loading}
        disabled={!start || !end}
        children={value ? getName(value) : t("ride.route_select")}
        title={label}
        onClick={e => setOpen(true)}
      />

      <Drawer
        visible={open}
        title={label}
        placement="bottom"
        height={400}
        onClose={() => {
          setOpen(false);
        }}
      >
        <List
          loading={loading}
          dataSource={routes}
          renderItem={route => (
            <List.Item>
              <List.Item.Meta title={getName(route)} description={".."} />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
}

function getName(route: GraphhopperPath) {
  return (
    formatDistance(route.distance * 1000) +
    " " +
    formatRelativeDates(Date.now() + route.time, Date.now())
  );
}
