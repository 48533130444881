import * as React from "react";

import {
  Chat,
  useIsSimple,
  MessengerChatInputDefault,
  MessengerChatInputSimple,
} from "..";

export function MessengerChatInput({ chat }: { chat: Chat }) {
  const isSimple = useIsSimple();

  if (!chat) {
    return null;
  }

  if (isSimple) {
    return <MessengerChatInputSimple chat={chat} />;
  }

  return <MessengerChatInputDefault chat={chat} />;
}
