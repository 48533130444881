export function getProfileImage(user) {
  try {
    return user
      .get("rsProfilePublic")
      .get("image")
      .url();
  } catch (error) {
    return null;
  }
}
