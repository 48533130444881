import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import {
  AppLayout,
  AppData,
  UserProfileRoute,
  MessengerRoute,
  RideOfferRoute,
  RideSearchRoute,
  HomeRoute,
} from "..";

export function AppRoute({ uri }) {
  return (
    <AppLayout>
      <AppData>
        <Routes>
          <Route path="home" element={<HomeRoute />} />
          <Route path="search" element={<RideSearchRoute />} />
          <Route path="offer" element={<RideOfferRoute />} />
          <Route path="messenger" element={<MessengerRoute />} />
          <Route path="profile" element={<UserProfileRoute />} />
          {/* <Route path="*" element={<DefaultRoute />} /> */}
        </Routes>
      </AppData>
    </AppLayout>
  );
}

function DefaultRoute({}) {
  const navigate = useNavigate();
  navigate("search");
  return null;
}
