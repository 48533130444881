import * as React from "react";
import { useDebounce, useObjectState, useStorage } from "opendash";
import { Location } from "..";

type SearchHistoryEntry = { start: Location; end: Location };

type Response = [SearchHistoryEntry[], (search: SearchHistoryEntry) => void];

export function useSearchHistory(): Response {
  const [history, setHistory] = useStorage<SearchHistoryEntry[]>(
    "device",
    "ride-sharing:search-recent",
    [],
    {
      encode: values => {
        return JSON.stringify(values);
      },
      decode: values => {
        return JSON.parse(values).map(entry => ({
          start: new Location(entry.start),
          end: new Location(entry.end),
        }));
      },
    }
  );

  const addEntry = React.useCallback(
    (newEntry: SearchHistoryEntry) => {
      const newHistory = history
        .filter(entry => {
          return !(
            Location.equals(entry.start, newEntry.start) &&
            Location.equals(entry.end, newEntry.end)
          );
        })
        .filter((entry, index) => index <= 3);

      newHistory.unshift(newEntry);

      setHistory(newHistory);
    },
    [history, setHistory]
  );

  return React.useMemo(() => [history, addEntry], [history, addEntry]);
}
