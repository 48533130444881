import * as React from "react";

import Parse from "parse";

import { Button, message } from "antd";
import { useTranslation, useUrlParam } from "opendash";
import {
  FormElement,
  LocationInput,
  TimeInput,
  Offer,
  Location,
  WeekdayInput,
  PlacesInput,
  Place,
  GraphhopperPath,
  RoutesInput,
} from "..";
import { usePlacesNearRoute, createParsePolygonFromRoute } from "..";
import { OfferRoute } from "../parse";

const containerStyle: React.CSSProperties = {
  padding: 24,
};

const switchButtonStyle: React.CSSProperties = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
};

const inputWithLabelStyle: React.CSSProperties = {
  width: "100%",
};

const submitButtonStyle: React.CSSProperties = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

export function RideOfferCreate() {
  const [t] = useTranslation("ride-sharing");

  const [open, setOpen] = useUrlParam("create", false, "json");

  const [start, setStart] = React.useState<Location>();

  const [end, setEnd] = React.useState<Location>();

  const [route, setRoute] = React.useState<GraphhopperPath>(null);
  const [places, setPlaces] = React.useState<Place[]>([]);
  const [time, setTime] = React.useState("8:30");
  const [weekdays, setWeekdays] = React.useState<(0 | 1 | 2 | 3 | 4 | 5 | 6)[]>(
    [1, 2, 3, 4, 5]
  );

  const { result: placesNearRoute } = usePlacesNearRoute(route);

  return (
    <div style={containerStyle}>
      <FormElement label={t("ride.start_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.start_select")}
          urlParam="select_start"
          location={start}
          setLocation={location => setStart(location)}
        />
      </FormElement>

      <Button
        size="large"
        shape="circle"
        type="primary"
        style={switchButtonStyle}
        icon="retweet"
        title={t("search.switch")}
        disabled={!start || !end}
        onClick={e => {
          setStart(end);
          setEnd(start);
        }}
      />

      <FormElement label={t("ride.end_label")}>
        <LocationInput
          style={inputWithLabelStyle}
          label={t("ride.end_select")}
          urlParam="select_end"
          location={end}
          setLocation={location => setEnd(location)}
        />
      </FormElement>

      <FormElement label={t("ride.route_label")}>
        <RoutesInput
          start={start}
          end={end}
          label={t("ride.route_label")}
          style={inputWithLabelStyle}
          value={route}
          urlParam="select_route"
          onChange={setRoute}
        />
      </FormElement>

      <FormElement label={t("ride.places_label")}>
        <PlacesInput
          pool={placesNearRoute}
          urlParam="select_places"
          label={t("ride.places_label")}
          description={t("ride.places_description")}
          style={inputWithLabelStyle}
          value={places}
          onChange={setPlaces}
        />
      </FormElement>

      <FormElement label={t("weekdays.label")} useLabel={false}>
        <WeekdayInput value={weekdays} setValue={setWeekdays} />
      </FormElement>

      <FormElement label={t("ride.time_label")}>
        <TimeInput time={time} setTime={setTime} style={inputWithLabelStyle} />
      </FormElement>

      <Button
        size="large"
        type="primary"
        style={submitButtonStyle}
        disabled={!start || !end || !weekdays?.length}
        children={t("offer.create.submit")}
        title={
          !start || !end || !weekdays?.length
            ? t("offer.create.submit_tooltip_disabled")
            : t("offer.create.submit_tooltip")
        }
        onClick={e => {
          const offerRoute = new OfferRoute({
            start,
            end,
            route: createParsePolygonFromRoute(route),
            routeFull: route,
          });

          const offer = new Offer({
            user: Parse.User.current(),
            route: offerRoute,
            time,
            weekdays,
            active: true,
          });

          places.forEach(place => {
            offer.relation("places").add(place);
          });

          offer.save().then(
            () => {
              message.success(t("offer.create.success"));
              setOpen(false);
            },
            error => {
              message.error(t("offer.create.error"));
              console.error(error);
            }
          );
        }}
      />
    </div>
  );
}
