import * as React from "react";

import useFetcher from "swr";

import {
  PublicTransportFetcher,
  PublicTransportRideInterface,
  useRideSearchStart,
  useRideSearchEnd,
  useRideSearchDate,
  useRideSearchTime,
} from "..";

import moment from "moment";

import { formatRelativeDates } from "opendash";

export function usePublicTransportRoute(): PublicTransportRideInterface[] {
  const [start] = useRideSearchStart();
  const [end] = useRideSearchEnd();
  const [date] = useRideSearchDate();
  const [time] = useRideSearchTime();

  const [hour, minute] = time.split(":");

  const timestamp = moment(date)
    .hour(parseInt(hour))
    .minute(parseInt(minute))
    .valueOf();

  const key = [
    "route",
    start?.get("location").latitude,
    start?.get("location").longitude,
    end?.get("location").latitude,
    end?.get("location").longitude,
    timestamp,
  ];

  const { data } = useFetcher(key.join("/"), PublicTransportFetcher);

  return React.useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map(item => {
      const time = moment(item?.legs?.[0]?.departureTime).format("HH:mm");
      const duration = formatRelativeDates(item?.properties?.duration, 0);
      const lines = [];
      const stations = [];
      const stops = [];

      item.legs.forEach(leg => {
        const line = leg.line?.label;
        const last_station = stations[stations.length - 1];
        const departure = leg.departure.name + ", " + leg.departure.place;
        const arriaval = leg.arrival.name + ", " + leg.arrival.place;

        if (line) {
          if (last_station !== departure) {
            stations.push(departure);
          }

          stations.push(arriaval);

          lines.push(line);

          stops.push({
            type: leg.line.product,
            title: line,
            description: `${departure} →  ${arriaval}`,
          });
        }

        if (leg.type === "WALK") {
          stops.push({
            type: "WALK",
            title: `Laufen`,
            description: `${leg.min}min / ${leg.distance}m`,
          });
        }
      });

      return {
        title: lines.join(", ") + " um " + time + " " + duration,
        description: stations.join(" → "),
        stops,
      };
    });
  }, [data]);
}
