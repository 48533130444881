import * as React from "react";

import { useTranslation } from "opendash";
import {} from "..";
import { Button } from "antd";

const style: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export function WeekdayInput({ value, setValue }) {
  const [t] = useTranslation("ride-sharing");

  function toggleWeekday(day) {
    if (!value || !Array.isArray(value)) {
      return void setValue([day]);
    }

    if (value.includes(day)) {
      return void setValue(value.filter(x => x !== day));
    } else {
      return void setValue([...value, day]);
    }
  }

  return (
    <div style={style}>
      {[1, 2, 3, 4, 5, 6, 0].map(i => (
        <Button
          key={i}
          size="large"
          shape="circle"
          type={value.includes(i) ? "primary" : "default"}
          children={t(`weekdays.day_${i}_label`)}
          title={t("ride.end_select")}
          onClick={e => toggleWeekday(i)}
        />
      ))}
    </div>
  );
}
