import * as React from "react";
import moment from "moment";

import { useStorage } from "opendash";

export function useRideSearchDate(): [number, (value: number) => void] {
  return useStorage(
    "device",
    "ride-sharing:search-date",
    moment()
      .startOf("day")
      .valueOf()
  );
}
