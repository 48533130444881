import React from "react";
import Parse from "parse";

import { ParseQueryResult } from "./types";

type Result<T> = {
  result: T[];
  count: number;
  loading: boolean;
  error: Error | undefined;
  reload: () => void;
};

export function useParseQuery<T extends Parse.Object>(
  query: Parse.Query<T>,
  options?: { count?: boolean; live?: boolean }
): Result<T> {
  const subscriptionRef = React.useRef<Parse.LiveQuerySubscription>();
  const initialState = {
    result: [],
    count: 0,
    loading: true,
    error: undefined,
    reload: fetch,
  };

  const [state, setState] = React.useState<Result<T>>(initialState);

  async function fetch() {
    if (query) {
      query.ascending("updatedAt");

      if (options?.count) {
        query.count();
      }

      if (options?.live) {
        if (subscriptionRef.current) {
          await subscriptionRef.current.unsubscribe();
        }

        subscriptionRef.current = await query.subscribe();

        subscriptionRef.current.on("open", () => {});

        subscriptionRef.current.on("create", (object: T) => {
          setState(mergeResult(object, true));
        });

        subscriptionRef.current.on("update", (object: T) => {
          setState(mergeResult(object, true));
        });

        subscriptionRef.current.on("enter", (object: T) => {
          setState(mergeResult(object, true));
        });

        subscriptionRef.current.on("leave", (object: T) => {
          setState(mergeResult(object));
        });
      }

      query.find().then(
        (response: ParseQueryResult<T>) => {
          if (Array.isArray(response)) {
            setState(
              mergeState({
                result: response,
                count: 0,
                loading: false,
              })
            );
          } else if (Array.isArray(response?.results) && response?.count) {
            setState(
              mergeState({
                result: response.results,
                count: response.count,
                loading: false,
              })
            );
          } else {
            setState(
              mergeState({ error: new Error("Bad Response"), loading: false })
            );
          }
        },
        (error: Error) => {
          setState(mergeState({ error, loading: false }));
        }
      );
    }
  }

  function mergeState(
    nextState: Partial<Result<T>>
  ): (state: Result<T>) => Result<T> {
    return state => Object.assign({}, state, nextState);
  }

  function mergeResult(
    object: T,
    add: boolean = false
  ): (state: Result<T>) => Result<T> {
    return state => {
      const result = state.result.filter(e => e.id !== object.id);

      if (add) {
        result.push(object);
      }

      return Object.assign({}, state, { result });
    };
  }

  React.useEffect(() => {
    setState(initialState);
    fetch();

    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [query]);

  return state;
}
