import React from "react";
import Parse from "parse";
import { useObjectState } from "opendash";

type ParseListResult<T> = {
  result: T[];
  count: number;
  loading: boolean;
  error: Error;
  reload: () => void;
};

export function useParseList<T>(
  cls: new (...args: any[]) => T,
  options?: { count?: boolean; includes?: string[] }
): ParseListResult<T> {
  const [state, setState, resetState] = useObjectState({
    result: [],
    count: 0,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (cls) {
      // @ts-ignore
      const query = new Parse.Query(cls as Parse.Object);

      query.descending("updatedAt");

      if (options?.count) {
        query.withCount();
      }

      if (options?.includes) {
        for (const include of options.includes) {
          query.include(include);
        }
      }

      query.find().then(
        response => {
          setState({
            result: options?.count ? response.results : response,
            count: options?.count ? response.count : 0,
            loading: false,
          });
        },
        error => {
          setState({ error, loading: false });
        }
      );
    }
  }

  React.useEffect(() => {
    setState({
      result: [],
      count: 0,
      loading: true,
      error: undefined,
      reload: fetch,
    });

    fetch();
  }, [cls]);

  return state;
}
