//@ts-check

if (!window.localStorage.getItem("opendash:language")) {
  window.localStorage.setItem("opendash:language", '"de"');
}

import "antd/dist/antd.min.css";

import {
  init,
  ParseUserAdapter,
  ParseStorageAdapter,
  StorageAdapterLS,
  ParseNavigationAdapter,
} from "opendash";

import { AppRoute, AdminRoute } from "./ride-sharing-app";

import "./parse.config";
import "./leaflet.config";

init("opendash", async factory => {
  factory.registerUserAdapter(new ParseUserAdapter());
  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  factory.registerUserStorageAdapter(
    new ParseStorageAdapter({
      userDataClass: "ODUserData",
    })
  );
  factory.registerNavigationAdapter(
    new ParseNavigationAdapter({
      navigationGroupClass: "ODNavigationGroup",
      navigationItemClass: "ODNavigationItem",
    })
  );

  factory.registerLanguage("de", "Deutsch");
  factory.registerLanguage("de_accessible", "Einfache Sprache", "de");
  factory.registerLanguage("en", "English");

  factory.registerTranslationResolver(
    "de",
    "opendash",
    async () => await import("./translations/opendash-de")
  );

  factory.registerTranslationResolver(
    "de",
    "antd",
    async () => await import("./translations/antd-de")
  );

  factory.registerTranslationResolver(
    "de",
    "ride-sharing",
    async () => await import("./translations/ride-sharing-de")
  );

  factory.registerTranslationResolver(
    "de_accessible",
    "ride-sharing",
    async () => {
      await import("./translations/ride-sharing-de");
      return await import("./translations/ride-sharing-de-accessible");
    }
  );

  factory.registerRoute({
    path: "/ride-sharing/*",
    component: async () => ({ default: AppRoute }),
  });

  factory.registerRoute({
    path: "/admin/ride-sharing/*",
    component: async () => ({ default: AdminRoute }),
  });

  factory.registerStaticNavigationGroup({
    id: "rs-admin-users",
    label: "ride-sharing:admin.users.label",
    icon: "user",
    order: 20,
  });

  factory.registerStaticNavigationGroup({
    id: "rs-admin-poi",
    label: "ride-sharing:admin.poi.label",
    icon: "pushpin",
    order: 30,
  });

  ["unverified", "verified", "banned", "admins"].forEach((key, index) => {
    factory.registerStaticNavigationItem({
      id: "rs-admin-users-" + key,
      group: "rs-admin-users",
      place: "sidebar",
      order: index,
      label: "ride-sharing:admin.users.label_" + key,
      link: "/admin/ride-sharing/users/" + key,
      routeCondition: "**/admin/**",
      activeCondition: "/admin/ride-sharing/users/" + key,
    });
  });

  ["permanent", "temporary"].forEach((key, index) => {
    factory.registerStaticNavigationItem({
      id: "rs-admin-poi-" + key,
      group: "rs-admin-poi",
      place: "sidebar",
      order: index,
      label: "ride-sharing:admin.poi.label_" + key,
      link: "/admin/ride-sharing/poi/" + key,
      routeCondition: "**/admin/**",
      activeCondition: "/admin/ride-sharing/poi/" + key,
    });
  });
}).then(app => {
  console.log("init open.DASH");
});
