import * as React from "react";

import Parse from "parse";
import styled from "styled-components";

import { useTranslation, useUrlParam, useElementSize } from "opendash";
import { useParseQuery } from "parse-hooks";
import { Divider } from "antd";
import { Message, MessengerChatInput, useChat, MessengerListItem } from "..";
import moment from "moment";
import { ListContainer } from "../layout/ListContainer";

const Container = styled.div``;

const Empty = styled.div`
  padding: 50px;
  text-align: center;
`;

const InputContainer = styled.div`
  position: fixed;

  bottom: 60px;
  left: 0;
  right: 0;

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 1;

  padding: 10px;
`;

const MessagesContainer = styled.div`
  height: calc(100vh - 60px - 60px - ${p => p.cHeight}px);
  padding: 10px;
  overflow-y: auto;
`;

const MessageContainer = styled.div`
  text-align: ${p => p.side};
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
`;

const MessageBubble = styled.div`
  display: inline-block;

  padding: 3px 10px;
  border-radius: 2px;
  max-width: 80%;
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;

  overflow-wrap: break-word;
`;

const MessageTime = styled.span`
  margin-left: 10px;
  margin-top: 5px;
  margin-right: -5px;

  float: right;
  font-size: 0.7em;
`;

const MessageDate = styled.div`
  /* text-align: center; */

  span {
    font-weight: normal;
    font-size: 0.7em;
    letter-spacing: 1px;
  }
`;

export function MessengerChat({}) {
  const [t] = useTranslation("ride-sharing");
  const [chatId, setChat] = useUrlParam("chat", null, "string");

  const controlRef = React.useRef<HTMLElement>(null);
  const messagesRef = React.useRef<HTMLElement>(null);
  const messagesHeaderRef = React.useRef<HTMLElement>(null);

  const controlsSize = useElementSize(controlRef);
  const messagesHeaderSize = useElementSize(messagesHeaderRef);
  const chat = useChat(chatId);

  const query = React.useMemo(
    () => (chat ? new Parse.Query(Message).equalTo("chat", chat) : null),
    [chat]
  );

  const { result: messages, loading } = useParseQuery(query, {
    live: true,
  });

  React.useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messages?.length]);

  if (!chat) {
    return null;
  }

  const uid = Parse.User.current().id;

  return (
    <Container>
      <ListContainer ref={messagesHeaderRef}>
        <MessengerListItem chat={chat} />
      </ListContainer>
      <MessagesContainer
        ref={messagesRef}
        cHeight={controlsSize.height + messagesHeaderSize.height}
      >
        {messages.length === 0 && <Empty children={t("messenger.empty")} />}
        {messages.length > 0 &&
          messages.map((message, i, arr) => {
            const isMyMessage = message.get("user").id === uid;
            const date = moment(message.createdAt).format("DD.MM.YYYY");
            const time = moment(message.createdAt).format("HH:mm");
            const prevDate =
              i > 0 ? moment(arr[i - 1].createdAt).format("DD.MM.YYYY") : null;
            const showDate = date !== prevDate;
            const messageContent = message.get("message");

            return (
              <MessageContainer
                key={message.id}
                side={isMyMessage ? "right" : "left"}
              >
                {showDate && (
                  <MessageDate>
                    <Divider>{date}</Divider>
                  </MessageDate>
                )}
                <MessageBubble>
                  <span>{messageContent}</span>
                  <MessageTime>
                    {/* <FormatRelativeDates start={message.createdAt} /> */}
                    <span children={time} />
                  </MessageTime>
                </MessageBubble>
              </MessageContainer>
            );
          })}
      </MessagesContainer>
      <InputContainer ref={controlRef}>
        <MessengerChatInput chat={chat} />
      </InputContainer>
    </Container>
  );
}
