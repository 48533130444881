import React from "react";

import { useChats } from "..";

export function useChatsNewCount(): number {
  const chats = useChats();

  const newChats = chats.filter(
    chat => chat.get("lastMessageUser")?.id !== Parse.User.current()?.id
  );

  return newChats.length;
}
