import React from "react";
import Parse from "parse";
import { Button, message } from "antd";

async function logout() {
  Parse.User.logOut().then(
    ok => {
      message.success("Erfolgreich ausgeloggt.");

      const key = "Parse/ride-sharing/currentUser";

      window.dispatchEvent(
        new StorageEvent("storage", {
          key,
          newValue: window.localStorage.getItem(key)
        })
      );
    },
    error => {
      console.error(error);
    }
  );
}

export function AppNavigation() {
  const user = Parse.User.current();

  if (!user) {
    return null;
  }

  return (
    <>
      <span style={{ marginRight: 10 }}>Hallo, {user.get("name")}</span>
      <Button onClick={logout}>Logout</Button>
    </>
  );
}
