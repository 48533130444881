import * as React from "react";
import moment from "moment";
import Parse from "parse";

import { Drawer, List, Descriptions, Button, message } from "antd";
import { useTranslation, useForceRender, ParseSourceAdapter } from "opendash";

import { useNavigate } from "react-router";
import {
  useParseGet,
  Place,
  Offer,
  getProfileImage,
  Chat,
  useRideSearchTime,
  useRideSearchDate,
  useRideSearchStart,
  useRideSearchEnd,
  usePlacesNearLocation,
} from "..";

interface Props {
  visible: boolean;
  offerId: string;
  onClose: () => void;
}

export const RideOfferRequest = React.memo<Props>(
  ({ visible, offerId, onClose }) => {
    const [t] = useTranslation("ride-sharing");
    const navigate = useNavigate();
    const forceRender = useForceRender();

    const { result: offer, loading, reload } = useParseGet(
      Offer,
      visible && offerId,
      { includes: ["user"] }
    );

    const [startLocation] = useRideSearchStart();
    const [endLocation] = useRideSearchEnd();
    const [time] = useRideSearchTime();
    const [date] = useRideSearchDate();

    const [contact, setContact] = React.useState(false);
    const [start, setStart] = React.useState<Place>();
    const [end, setEnd] = React.useState<Place>();

    const placesBaseQuery: Parse.Query<Place> = React.useMemo(
      () => (offer ? offer.get("places").query() : null),
      [offer]
    );

    const { result: places, loading: placesLoading } = usePlacesNearLocation(
      start ? endLocation : startLocation,
      placesBaseQuery
    );

    const user = offer?.get("user");
    const avatar = getProfileImage(user);

    React.useEffect(() => {
      if (user) {
        user
          .get("rsProfilePublic")
          .fetch()
          .then(() => {
            forceRender();
          });
      }
    }, [user]);

    React.useEffect(() => {
      setContact(false);
      setStart(null);
      setEnd(null);
    }, [offerId]);

    if (start && end) {
      return (
        <Drawer
          visible={visible}
          title={t("offer.request.title", { name: user?.get("name") })}
          placement="bottom"
          height={600}
          onClose={onClose}
        >
          <p>{t("offer.request.confirm_text")}</p>
          <Descriptions>
            <Descriptions.Item
              label={t("ride.driver")}
              children={offer.get("user").get("name")}
            />

            <Descriptions.Item
              label={t("offer.request.start_place")}
              children={`${start.get("name")} (${start.get("type")})`}
            />

            <Descriptions.Item
              label={t("offer.request.end_place")}
              children={`${end.get("name")} (${end.get("type")})`}
            />

            <Descriptions.Item
              label={t("ride.date_label")}
              children={moment(date).format("DD.MM.YYYY")}
            />

            <Descriptions.Item label={t("ride.time_label")} children={time} />
          </Descriptions>
          <Button
            type="primary"
            children={t("offer.request.confirm_action")}
            onClick={() => {
              const chat = new Chat({
                offer,
                start,
                end,
                time,
                date,
                locked: false,
                lastMessageAt: new Date(),
                lastMessageUser: Parse.User.current(),
              });
              chat.save().then(
                () => {
                  message.success(t("offer.request.success"));
                  navigate("/ride-sharing/messenger?chat=" + chat.id);
                },
                error => {
                  message.error(t("offer.request.error"));
                  console.error(error);
                }
              );
            }}
          />
        </Drawer>
      );
    }

    return (
      <Drawer
        visible={visible}
        title={t("offer.request.title", { name: user?.get("name") })}
        placement="bottom"
        height={600}
        onClose={onClose}
      >
        <p>
          {t(
            start
              ? "offer.request.select_end_place"
              : "offer.request.select_start_place"
          )}
        </p>

        <List
          loading={placesLoading}
          dataSource={places}
          renderItem={place => (
            <List.Item
              actions={[
                <Button
                  children={t("offer.request.select_place")}
                  onClick={() => {
                    if (start) {
                      setEnd(place);
                    } else {
                      setStart(place);
                    }
                  }}
                />,
              ]}
            >
              <List.Item.Meta
                title={place.get("type")}
                description={place.get("name")}
              />
            </List.Item>
          )}
        />
      </Drawer>
    );
  }
);
