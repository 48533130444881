import * as React from "react";

import styled from "styled-components";

import {} from "opendash";
import {} from "..";

const Label = styled.label`
  display: block;
  margin-bottom: 24px;
`;

const LabelDiv = styled.div`
  display: block;
  margin-bottom: 24px;
`;

const LabelText = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 8px;
  line-height: 8px;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export function FormElement({ label, children, useLabel = true }) {
  const Container = useLabel ? Label : LabelDiv;

  return (
    <Container>
      <LabelText>{label}</LabelText>
      {children}
    </Container>
  );
}
