import * as React from "react";

import Parse from "parse";
import moment from "moment";

import { useTranslation, useUrlParam } from "opendash";
import {
  TitleContainer,
  ListContainer,
  useRideSearchStart,
  useRideSearchEnd,
  useRideSearchTime,
  useRideSearchDate,
  Search,
} from "..";

import { Button, DatePicker, Divider, List } from "antd";
import { useParseQuery } from "parse-hooks";

const containerStyle: React.CSSProperties = {
  paddingTop: 24,
};

const buttonStyle: React.CSSProperties = {
  width: "100%",
  marginBottom: 24,
};

export function RideSearchInputSimple({}) {
  const [t] = useTranslation("ride-sharing");

  const [view, setView] = useUrlParam<string>("view", null);

  const [start, setStart] = useRideSearchStart();
  const [end, setEnd] = useRideSearchEnd();
  const [time, setTime] = useRideSearchTime();
  const [date, setDate] = useRideSearchDate();

  const query = React.useMemo(
    () =>
      new Parse.Query(Search)
        .include("start")
        .include("end")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  return (
    <>
      <TitleContainer>{t("search.label")}</TitleContainer>
      <ListContainer style={containerStyle}>
        {result.map(search => (
          <Button
            key={search.id}
            size="large"
            type="primary"
            style={buttonStyle}
            // icon="retweet"
            title={t("search.switch")}
            children={`${search.get("start").get("title")} @ ${search.get(
              "time"
            )}`}
            onClick={e => {
              setStart(search.get("start"));
              setEnd(search.get("end"));
              setTime(search.get("time"));
              setDate(Date.now());

              setView("results");
            }}
          />
        ))}
      </ListContainer>
    </>
  );
}
