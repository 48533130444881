import * as React from "react";

import { useStorage } from "opendash";
import { Location } from "..";

export function useRideSearchStart(): [Location, (value: Location) => void] {
  return useStorage<Location>(
    "device",
    "ride-sharing:search-start",
    undefined,
    Location.StorageOptions
  );
}
