import * as React from "react";

import styled from "styled-components";

import { useTranslation } from "opendash";
import { Input, message as m } from "antd";
import { Chat, Message } from "..";

const Container = styled.div``;

export function MessengerChatInputDefault({ chat }: { chat: Chat }) {
  const [t] = useTranslation("ride-sharing");
  const [messageDraft, setMessageDraft] = React.useState("");

  return (
    <Input.TextArea
      rows={1}
      autoSize
      value={messageDraft}
      onChange={e => setMessageDraft(e.target.value)}
      placeholder={t("messenger.input.placeholder")}
      onPressEnter={e => {
        e.preventDefault();

        const msg = new Message({ chat, message: messageDraft.trim() });

        msg.save().then(
          () => {
            setMessageDraft("");
            // reload();
          },
          error => {
            m.error(t("messenger.message.send_error"));
            console.error(error);
          }
        );
      }}
    />
  );
}
