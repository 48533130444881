import React from "react";
import Parse from "parse";

import {
  GraphhopperPath,
  Place,
  useParseQuery,
  createParsePolygonFromRoute,
} from "..";

type ParseListResult<T> = {
  result: Place[];
  loading: boolean;
  error: Error;
  reload: () => void;
};

export function usePlacesNearRoute<T>(
  route: GraphhopperPath
): ParseListResult<T> {
  const polygon = React.useMemo(() => createParsePolygonFromRoute(route), [
    route,
  ]);

  const query = React.useMemo(
    () =>
      polygon
        ? new Parse.Query(Place)
            .equalTo("enabled", true)
            .withinPolygon("location", polygon)
        : null,
    [polygon]
  );

  return useParseQuery(query);
}
