import * as React from "react";

import Parse from "parse";

import {
  Location,
  Place,
  Offer,
  useRideSearchDate,
  useRideSearchTime,
  useRideSearchStart,
  useRideSearchEnd,
} from "..";
import moment from "moment";

export function useRideSearch(): Offer[] {
  const [state, setState] = React.useState<Offer[]>([]);

  const [start] = useRideSearchStart();
  const [end] = useRideSearchEnd();
  const [time] = useRideSearchTime();
  const [date] = useRideSearchDate();

  React.useEffect(() => {
    if (start && end) {
      fetchOffers({
        start_latitude: start.get("location").latitude,
        start_longitude: start.get("location").longitude,
        end_latitude: end.get("location").latitude,
        end_longitude: end.get("location").longitude,
        weekday: moment(date).weekday(),
        time,
        date,
      }).then(
        result => {
          setState(result);
        },
        error => {
          console.error(error);
        }
      );
    }
  }, [start, end]);

  return state;
}

async function fetchOffers(params): Promise<Offer[]> {
  const result = await Parse.Cloud.run("rsSearch", params);

  const offers: Offer[] = result.offers.map(id => Offer.createWithoutData(id));

  await Offer.fetchAll(offers, {});

  for (const offer of offers) {
    const user = offer.get("user");

    await user.fetch();

    const profile = user.get("rsProfilePublic");

    await profile.fetch();
  }

  return offers;
}
