import * as React from "react";

import useFetcher from "swr";

import {
  useRideSearchStart,
  PublicTransportFetcher,
  PublicTransportRideInterface,
} from "..";

export function usePublicTransportNearby(): PublicTransportRideInterface[] {
  const [start] = useRideSearchStart();

  const key = [
    "nearby",
    start.get("location").latitude,
    start.get("location").longitude,
    1000,
  ];

  const { data } = useFetcher(key.join("/"), PublicTransportFetcher);

  return React.useMemo(() => {
    console.log(data);

    return [];
  }, [data]);
}
