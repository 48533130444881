import * as React from "react";

import { useTranslation } from "opendash";

import {
  useRideSearch,
  ListContainer,
  TitleContainer,
  usePublicTransportRoute,
  PublicTransportList,
  RideOfferResultList,
  TaxiInfoList,
} from "..";

export function HomeRoute() {
  const [t] = useTranslation("ride-sharing");

  const pt = usePublicTransportRoute(); // usePublicTransportNearby();
  const offers = useRideSearch();

  return (
    <>
      <TitleContainer>{t("search.ride_offers")}</TitleContainer>
      <ListContainer>
        <RideOfferResultList data={offers} />
      </ListContainer>
      <TitleContainer>{t("search.public_transport_offers")}</TitleContainer>
      <ListContainer>
        <PublicTransportList data={pt} />
      </ListContainer>
      <TitleContainer>{t("search.cap_offers")}</TitleContainer>
      <ListContainer>
        <TaxiInfoList />
      </ListContainer>
    </>
  );
}
