import * as React from "react";
import Parse from "parse";

import { Button, List, Modal, message, Menu, Icon, Dropdown } from "antd";
import { useTranslation, useUrlParam } from "opendash";
import { useParseQuery } from "parse-hooks";
import { Search, ListContainer, TitleContainer, FormatWeekdays } from "..";

const createButtonStyle: React.CSSProperties = {
  float: "right",
};

export function RideSearchList({}) {
  const [t] = useTranslation("ride-sharing");
  const [view, setView] = useUrlParam<string>("view", null);

  const query = React.useMemo(
    () =>
      new Parse.Query(Search)
        .include("start")
        .include("end")
        .equalTo("user", Parse.User.current()),
    []
  );

  const { result, loading, reload } = useParseQuery(query);

  React.useEffect(() => {
    if (!loading && result.length === 0) {
      setView("create");
    }
  }, [result, loading]);

  return (
    <>
      <TitleContainer>
        <Button
          type="primary"
          shape="circle"
          style={createButtonStyle}
          icon="plus"
          title={t("search.create.action")}
          onClick={() => {
            setView("create");
          }}
        />

        {t("search.label")}
      </TitleContainer>

      <ListContainer>
        <List
          loading={loading}
          dataSource={result}
          renderItem={item => {
            const onDelete = () => {
              Modal.confirm({
                title: t("search.delete.confirm_title"),
                content: t("search.delete.confirm_content"),
                okText: t("search.delete.ok"),
                okType: "danger",
                onOk: () => {
                  item.destroy().then(
                    ok => {
                      message.success(t("search.delete.success"));
                      reload();
                    },
                    error => {
                      console.error(error);
                      message.success(t("search.delete.error"));
                    }
                  );
                },
              });
            };

            const menu = (
              <Menu>
                <Menu.Item key="delete" onClick={onDelete}>
                  <Icon type="delete" />
                  <span>{t("search.delete.action")}</span>
                </Menu.Item>
                <Menu.Item key="edit" disabled>
                  <Icon type="edit" />
                  <span>{t("search.edit.action")}</span>
                </Menu.Item>
              </Menu>
            );

            return (
              <List.Item
                actions={[
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    children={
                      <Button type="default" shape="circle" icon="more" />
                    }
                  />,
                ]}
              >
                <List.Item.Meta
                  title={`${item.get("start").get("title")} → ${item
                    .get("end")
                    .get("title")}`}
                  description={
                    <>
                      <FormatWeekdays days={item.get("weekdays")} />
                      <span> @ {item.get("time")}</span>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
      </ListContainer>
      <div style={{ textAlign: "center", padding: 24 }}>
        <Button
          type="primary"
          size="large"
          children={t("search.create.action")}
          onClick={() => {
            setView("create");
          }}
        />
      </div>
    </>
  );
}
