import React from "react";
import Parse from "parse";
import { message } from "antd";
import { ChatContext, Chat } from "..";
import { useParseQuery } from "parse-hooks";

export function AppData({ children }) {
  const [[loading, error], setState] = React.useState([true, null]);

  React.useEffect(() => {
    const user = Parse.User.current();

    user
      .fetch()
      .then(() => user.get("rsProfilePublic").fetch())
      .then(() => user.get("rsProfilePrivate").fetch())
      .then(
        () => {
          setState([false, null]);
        },
        err => {
          if (err.code === 209) {
            message.error("Deine Session ist abgelaufen.");

            return void Parse.User.logOut().finally(() => {
              window.location.reload();
            });
          }

          message.error("Fehler beim laden der Nutzerdaten");
          console.error(err);

          setState([false, err]);
        }
      );
  }, []);

  if (loading || error) {
    return null;
  }

  return <ChatProvider>{children}</ChatProvider>;
}

function ChatProvider({ children }) {
  const query = React.useMemo(
    () =>
      new Parse.Query(Chat)
        // TODO: Not working
        .descending("lastMessageAt")
        .include("offer")
        .include("requestUser")
        .include("offerUser")
        .include("requestUserProfile")
        .include("offerUserProfile")
        .include("start")
        .include("end"),
    []
  );

  const { result, reload } = useParseQuery(query);
  const { result: resultLive } = useParseQuery(query, { live: true });

  React.useEffect(() => {
    reload();
  }, [resultLive]);

  return <ChatContext.Provider value={result}>{children}</ChatContext.Provider>;
}
