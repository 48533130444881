import * as React from "react";

import { useObjectState } from "opendash";
import { Location, GraphhopperPath } from "..";

type Response = {
  loading: boolean;
  error: any;
  result: GraphhopperPath[];
};

export function useGraphhopper(start: Location, end: Location): Response {
  const [state, setState] = useObjectState<Response>({
    loading: false,
    error: null,
    result: [],
  });

  React.useEffect(() => {
    if (!start || !end) {
      return;
    }

    setState({
      loading: true,
      error: null,
    });

    const controller = new AbortController();
    const signal = controller.signal;

    const q = [
      "point=" +
        start.get("location").latitude +
        "," +
        start.get("location").longitude,
      "point=" +
        end.get("location").latitude +
        "," +
        end.get("location").longitude,
      "locale=de-DE",
      "vehicle=car",
      "weighting=fastest",
      "elevation=false",
      "use_miles=false",
      "points_encoded=false",
    ];

    fetch("https://graphhopper.zill.es/route?" + q.join("&"), {
      signal,
    })
      .then(response => response.json())
      .then(response => {
        setState({
          loading: false,
          error: null,
          result: response.paths,
        });
      })
      .catch(error => {
        if (error.name === "AbortError") {
          return;
        }

        console.error(error);

        setState({
          loading: false,
          error,
          result: [],
        });
      });

    return () => {
      controller.abort();
    };
  }, [start, end]);

  return state;
}
