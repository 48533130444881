import Parse from "parse";

Parse.initialize("ride-sharing");
Parse.serverURL = "https://ride-sharing.zill.es/parse";

Parse.LiveQuery.on("open", () => {
  console.log("socket connection established");
});

Parse.LiveQuery.on("close", () => {
  console.log("socket connection closed");
});

Parse.LiveQuery.on("error", error => {
  console.error(error);
});
