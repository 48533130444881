import * as React from "react";
import { Input, Drawer, List, Divider, Button } from "antd";
import { useTranslation } from "opendash";
import { useNominatim, Location } from "..";
import { useParseList } from "../hooks/useParseList";

interface Props {
  loading: boolean;
  locations: Location[];
  onChange: (location: Location) => void;
}

export const LocationList = React.memo<Props>(
  ({ loading, locations, onChange }) => {
    const [t] = useTranslation("ride-sharing");
    return (
      <List
        loading={loading}
        dataSource={locations}
        renderItem={item => (
          <List.Item
            onClick={() => {
              onChange(item);
            }}
            actions={[
              <Button
                type="link"
                icon={t("search.select_icon")}
                children={t("search.select")}
              />,
            ]}
          >
            <List.Item.Meta
              title={item.get("title")}
              description={item.get("description")}
            />
          </List.Item>
        )}
      />
    );
  }
);
