import React from "react";
import Parse from "parse";

import { ParseQueryResult, ParseQueryResultWithCount } from "./types";

import { useObjectState } from "./useObjectState";

type Result<T> = {
  result: T | undefined;
  loading: boolean;
  error: Error | undefined;
  reload: () => void;
};

export function useParseQueryFirst<T extends Parse.Object>(
  query: Parse.Query<T>
): Result<T> {
  const [state, setState, resetState] = useObjectState<Result<T>>({
    result: undefined,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (query) {
      query.first().then(
        (response: T) => {
          setState({
            result: response,
            loading: false,
          });
        },
        (error: Error) => {
          setState({ error, loading: false });
        }
      );
    }
  }

  React.useEffect(() => {
    resetState();
    fetch();
  }, [query]);

  return state;
}
