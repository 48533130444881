import * as React from "react";
import { Input, Drawer, List, Divider, Button } from "antd";
import { useTranslation } from "opendash";
import { useNominatim, Location, LocationList, useParseList } from "..";

interface Props {
  visible: boolean;
  label: string;
  onClose: () => void;
  onChange: (location: Location) => void;
}

export const LocationSearch = React.memo<Props>(
  ({ visible, label, onChange, onClose }) => {
    const [t] = useTranslation("ride-sharing");
    const [query, setQuery] = React.useState<string>("");

    const {
      loading: recentLoading,
      error: recentError,
      result: recentLocations,
    } = useParseList(visible ? Location : null);

    const { loading, error, result } = useNominatim(query);

    return (
      <Drawer
        visible={visible}
        title={label}
        placement="bottom"
        height={400}
        onClose={onClose}
      >
        <Input.Search
          loading={loading}
          onChange={e => {
            setQuery(e.target.value);
          }}
        />

        <Divider>{t(query ? "locations.result" : "locations.recent")}</Divider>

        <LocationList
          loading={query ? loading : recentLoading}
          locations={query ? result : recentLocations}
          onChange={item => {
            if (!item.id) {
              const recent = recentLocations.find(
                loc => item.get("osm") === loc.get("osm")
              );

              if (recent) {
                item = recent;
              }
            }

            item.save().then(() => {
              onChange(item);
              onClose();
            });
          }}
        />
      </Drawer>
    );
  }
);
