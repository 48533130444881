import * as React from "react";

import { useTranslation, useUrlParam } from "opendash";
import { MessengerChat, MessengerList } from "..";

export function MessengerRoute({  }) {
  const [t] = useTranslation("ride-sharing");

  const [chat, setChat] = useUrlParam("chat", null, "string");

  if (chat) {
    return <MessengerChat />;
  }

  return <MessengerList />;
}
