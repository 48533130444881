import * as React from "react";
import { useUrlParam } from "opendash";
import {} from "..";
import { RideOfferCreate } from "./RideOfferCreate";
import { RideOfferOverview } from "./RideOfferOverview";

export function RideOfferRoute({  }) {
  const [createView, setCreateView] = useUrlParam("create", false, "json");

  if (createView) {
    return <RideOfferCreate />;
  }

  return <RideOfferOverview />;
}
