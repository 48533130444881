import * as React from "react";

import { useTranslation } from "opendash";
import { List } from "antd";

import {
  TitleContainer,
  Chat,
  ListContainer,
  MessengerListItem,
  useChats,
} from "..";

export function MessengerList() {
  const [t] = useTranslation("ride-sharing");
  const chats = useChats();

  return (
    <>
      <TitleContainer>{t("messenger.title")}</TitleContainer>
      <ListContainer>
        <List
          dataSource={chats}
          renderItem={(chat: Chat) => {
            return <MessengerListItem chat={chat} hasLink />;
          }}
        />
      </ListContainer>
    </>
  );
}
