import * as React from "react";

import Parse from "parse";

import { useDebounce, useObjectState } from "opendash";
import { Location } from "..";

type Response = {
  loading: boolean;
  error: any;
  result: Location[];
};

export function useNominatim(query: string): Response {
  const [state, setState] = useObjectState<Response>({
    loading: false,
    error: null,
    result: [],
  });

  const queryDebounced = useDebounce(query, 500);

  React.useEffect(() => {
    if (!queryDebounced || queryDebounced.length < 5) {
      return;
    }

    setState({
      loading: true,
      error: null,
    });

    const controller = new AbortController();
    const signal = controller.signal;
    const q = [
      "format=json",
      "addressdetails=1",
      "limit=3",
      "q=" + encodeURIComponent(queryDebounced),
    ];

    fetch("https://nominatim.openstreetmap.org/search?" + q.join("&"), {
      signal,
    })
      .then(response => response.json())
      .then(response => {
        const result = (response as any[]).map(result => {
          const { address: a } = result;

          const firstKey = Array.from(Object.keys(a))[0];

          const street =
            a.house_number && a.road
              ? `${a.road} ${a.house_number}`
              : a.road
              ? a.road
              : "";

          let title = a[firstKey];
          let description = [
            a.postcode,
            a.city,
            a.town,
            a.village,
            a.county ? `(${a.county})` : null,
          ]
            .filter(Boolean)
            .join(" ");

          switch (firstKey) {
            case "university":
              title = `Universität - ${a.university}`;
              break;

            case "house_number":
            case "road":
              title = street;
              break;

            default:
              break;
          }

          return new Location({
            osm: result.osm_id,
            title,
            description,
            location: new Parse.GeoPoint(
              parseFloat(result.lat),
              parseFloat(result.lon)
            ),
          });
        });

        setState({
          loading: false,
          error: null,
          result,
        });
      })
      .catch(error => {
        if (error.name === "AbortError") {
          return;
        }

        console.error(error);

        setState({
          loading: false,
          error,
          result: [],
        });
      });

    return () => {
      controller.abort();
    };
  }, [queryDebounced]);

  return state;
}
