import * as React from "react";
import * as Parse from "parse";

import { useTranslation } from "opendash";
import { message as m, Button, Drawer } from "antd";
import { Chat, Message, QuickMessage } from "..";
import { useParseQuery } from "parse-hooks";

const ButtonStyle: React.CSSProperties = {
  width: "100%",
};

const ButtonMBStyle: React.CSSProperties = {
  width: "100%",
  marginBottom: 24,
};

export function MessengerChatInputSimple({ chat }: { chat: Chat }) {
  const [t] = useTranslation("ride-sharing");
  const [open, setOpen] = React.useState(false);

  const query = React.useMemo(() => new Parse.Query(QuickMessage), []);
  const { result: quickmessages } = useParseQuery(query);

  return (
    <>
      <Button
        type="primary"
        style={ButtonStyle}
        children={t("messenger.quickmessages.action")}
        onClick={e => {
          setOpen(true);
        }}
      />
      <Drawer
        title={t("messenger.quickmessages.title")}
        visible={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        height={55 + 24 + quickmessages.length * 56}
      >
        {quickmessages.map((quickmessage, i, a) => (
          <Button
            key={quickmessage.get("label")}
            type="primary"
            style={a.length - 1 > i ? ButtonMBStyle : ButtonStyle}
            children={quickmessage.get("label")}
            onClick={e => {
              const msg = new Message({
                chat,
                message: quickmessage.get("message"),
              });

              msg.save().then(
                () => {
                  setOpen(false);
                },
                error => {
                  m.error(t("messenger.message.send_error"));
                  console.error(error);
                }
              );
            }}
          />
        ))}
      </Drawer>
    </>
  );
}
